import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import { useContext, useEffect, useState } from 'react'
import { userorder } from '../../context/interfaces'
import moment from 'moment'
import PaginationLayout from '../../components/PaginationLayout'
import Spinner from '../../components/BootstrapCompo'
import DownloadFileModal from '../../components/common/download-file-modal'
import OrderListTr from '../../components/common/OrderListTr'
import { OrderListResponse } from '../../context/interfaces/OrderInterface'
import NODATA from '../../assets/images/no-data-found.svg'
import { toast } from "react-toastify";
const UserOrderDetails = ({ id }: any) => {
    const { authState } = useContext(GlobalContext);
    const match = useMatch(`/user/:id`)
    const [state, setState] = useState<OrderListResponse>()
    const [userRequestData, setUserRequestdata] = useState() as any;
    const [userRequestCount, setUserRequestCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rewardStatus,setRewardStatus] = useState(false)
    const location = useLocation()
    let limit: Number = 10;
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1)
    const newParam = new URLSearchParams(location.search);
    const onChangePagination = (newVal: Number) => {
        setPage(Number(newVal))
    }
    const changeGraphType = (type: any) => {
        const newParam = new URLSearchParams()
        if (type) {
            newParam.set("order_status", type)
        }
        navigate({ search: newParam.toString() })
    }
    const handleSearch = (name: string, value: any) => {
        if (value) {
            newParam.set(name, value)
        } else {
            if (name === 'start_date') {
                newParam.delete('end_date')
            }
            if (newParam.has(name)) {
                newParam.delete(name)
            }
        }
        navigate({ pathname: `/user/${id}`, search: newParam.toString() })
    }
    const initialise = async () => {
        setLoading(true)
        try {
            let apiRes = await henceforthApi.User.getUserOrderDetails(
                page - 1,
                limit,
                newParam.toString(),
                match?.params?.id as string
            )
            setState(apiRes)
            setTotalCount(apiRes?.data?.total_count)
        } catch (error) {
            // handleError(error)
        } finally {
            setLoading(false)
        }
    }




    const initialiseEarnings = async () => {
        setLoading(true)
        try {
            let apiRes = await henceforthApi.User.getUsersEarnings(
                page - 1,
                limit,
                newParam.toString(),
                match?.params?.id as string
            )
            console.log(apiRes,"apiResapiRes")
            setUserRequestdata(apiRes)
            setUserRequestCount(apiRes?.data?.count)
        } catch (error) {
            // handleError(error)
        } finally {
            setLoading(false)
        }
    }




    const handlePayMoney = async(id:any) =>{
        debugger
        try {
         
            let payLoad = {
                    is_paid: true,
                    language: "ENGLISH"
            }
            const apiRes = await henceforthApi.User.payRewardMoney(id,payLoad);
            toast.success(apiRes?.message)
            initialiseEarnings();
           
        } catch (error) {
            
        }finally{
            setRewardStatus(false)
        }
    }
console.log(rewardStatus,"rewardStatus")
    
    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.User.getUserOrderExport(match?.params.id, startDate, endDate)
            const data = apiRes?.data?.data
            const rows = [
                [
                    "Order Id",
                    "Seller Name",
                    "Product Name",
                    "Order Status",
                    "Product Price  "
                ],
            ];
            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    rows.push([
                        res._id,
                        res.seller_id?.name,
                        res.product_id?.name,
                        res?.order_status,
                        res?.total_price
                    ])
                })
            }
            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `order_${match?.params.id}${moment().valueOf()}.csv`);
            document.body.appendChild(link);
            link.click();
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
            // debugger
        } catch (error) {
            handleError(error)
        }
    }
    useEffect(()=>{
        initialiseEarnings();
    },[])
    useEffect(() => {
        initialise()
    }, [page, newParam.get('start_date'), newParam.get('end_date'), newParam.get("order_status"), newParam.get("search"), authState?.lang])

    return (
        <div className="col-12 col-xxl-9">
            <div className='common-card mb-4 border-0 card-spacing'>
                <div className="row justify-content-between flex-wra gy-3">
                    {/* serach and filter  */}
                    <div className="col-12 col-xl-7">
                        <div className="row">
                            <div className="col-8">
                                <div className='form-fields-box'>
                                    <label className='mb-1 form-label fw-semibold'>Search</label>
                                    <div className='position-relative'>
                                        <input type="search" className="form-control rounded-0 ps-4 " name="search" placeholder="Search Seller name,Product name..."
                                            onChange={(e: any) => {
                                                handleSearch(e.target.name, e.target.value)
                                            }}
                                        />
                                        <span className='search-icon'><i className='fa fa-search'></i></span>
                                    </div>
                                </div>
                            </div>
                            {/* filter */}
                            <div className="col-4">
                                <div className='form-select-box'>
                                    <label className='mb-1 form-label fw-semibold'>Filter</label>
                                    <select className="form-select shadow-none" aria-label="Default select example"
                                        onChange={(e) => changeGraphType(String(e.target.value))} value={String(newParam.get("order_status")).toUpperCase()}
                                    >
                                        <option value="" >Filter By</option>
                                        <option value="PLACED">PLACED</option>
                                        {/* <option value="CONFIRMED">CONFIRMED</option> */}
                                        <option value="DELIVERED">DELIVERED</option>
                                        <option value="CANCELLED">CANCELLED</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* filter by date  */}
                    <div className="col">
                        <div className='d-flex justify-content-start flex-wrap flex-sm-nowrap gap-2'>
                            <div className='form-fields-box'>
                                <label className='mb-1 form-label fw-semibold'>Start date:</label>
                                <input type="date" className='form-control rounded-0' name='start_date' max={new Date().toISOString().split("T")[0]} onKeyDown={(e) => e.preventDefault()} value={newParam.has("start_date") ? moment(Number(newParam.get('start_date'))).format("YYYY-MM-DD") : moment().format("MM/DD/YYYY")} placeholder='mm/dd/yyyy'
                                    onChange={(e) => handleSearch(e.target.name, e.target.valueAsNumber)}
                                />
                            </div>
                            <div className='form-fields-box'>
                                <label className='mb-1 form-label fw-semibold'>End date:</label>
                                <input type="date" className='form-control rounded-0' name='end_date' max={new Date().toISOString().split("T")[0]} onKeyDown={(e) => e.preventDefault()} value={newParam.has("end_date") ? moment(Number(newParam.get('end_date'))).format("YYYY-MM-DD") : moment().format("MM/DD/YYYY")} placeholder='mm/dd/yyyy'
                                    disabled={!(newParam.has("start_date"))}
                                    min={newParam.has("start_date") ? moment(Number(newParam.get('start_date'))).add(1, 'days').format("YYYY-MM-DD") : moment().format("MM/DD/YYYY")}
                                    onChange={(e) => handleSearch(e?.target?.name, e?.target?.valueAsNumber)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* export  */}
                    <div className="col">
                        <div className='d-flex gap-3'>
                            <div className='download-export-box'>
                                <label className='mb-1 form-label fw-semibold'>Export File</label>
                                <div className="export-button">
                                    <button className="btn btn-white" type="button" data-bs-toggle="modal" data-bs-target="#fileDownloadModal"> <i className='fa fa-cloud-download me-2'></i>.csv</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="common-card mb-4">
                <div className="common-card-title">
                    <h5>Orders Detail</h5>
                </div>

                <div className="common-card-content">
                    {/* table */}
                    {loading ? <div className='vh-100 d-flex justify-content-center py-5'><Spinner /></div> :
                        <div className='data-list-table table-responsive mb-3'>
                            <table className="table table-striped align-middle">
                                <thead className=''>
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Order Id</th>
                                        <th>Seller Detail</th>
                                        <th>Product Detail</th>
                                        <th>Order Status</th>
                                        <th>Order Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(state?.data?.data) && (state?.data?.data.length) ? state.data?.data?.map((res:any,index:number) =>
                                        <OrderListTr key={res._id}  {...res} openFor="seller_id" index={index}/>
                                    ) : <tr><td className='text-center py-3' colSpan={6}><img src={NODATA} width="100" /><p className='text-center mt-3'>No data found</p></td></tr>}


                                </tbody>
                            </table>
                        </div>}
                    {/* pagination  */}
                    <div className='dashboad-pagination-box'>
                        <PaginationLayout
                            count={totalCount}
                            data={state?.data?.data}
                            page={Number(page)}
                            limit={Number(limit)}
                            loading={loading}
                            onPageChange={(val: any) => onChangePagination(val)}
                        />
                    </div>
                </div>
            </div>



            {/* ...campaign request.... */}
            <div className="common-card x">
                <div className="common-card-title d-flex justify-content-between">
                    <h5>Withdrawl Requests</h5>
                    <div className='bg-gray'>
                        <h6>Total Earnings : <span><b>&#36;</b>{userRequestData?.data?.total_earning}</span></h6>
                    </div>
                    
                </div>
             

                <div className="common-card-content">
                    {/* table */}
                    {loading ? <div className='vh-100 d-flex justify-content-center py-5'><Spinner /></div> :
                        <div className='data-list-table table-responsive mb-3'>
                            <table className="table table-striped align-middle">
                                <thead className=''>
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Campaign Name</th>
                                        <th>Amount </th>
                                        <th>Reward Amount</th>
                                        <th>Payment Status</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(userRequestData?.data?.data) && (userRequestData?.data?.data.length) ? userRequestData?.data?.data?.map((res:any,index:any) =>
                                        <tr key={res.id}>
                                            <td>{(index + 1)}</td>
                                            {/* <td>{Number(match?.params?.page) == 0 ? index + 1 : (Number(match?.params?.page) - 1) * limit + (index + 1)}</td> */}
                                            {/* <td title={res.user_id.name} className='product-image-table'> <img src={res.user_id?.profile_pic ? henceforthApi.FILES.imageSmall(res?.user_id?.profile_pic) : profile_placeholder} alt="img" className='rounded-circle me-2' /> {res.user_id?.name ? `${res.user_id?.name.length > 30 ? res.user_id?.name.slice(0, 30) + '...' : res?.user_id?.name} ` : `Not Available`}</td> */}
                                            <td title={res?.campaign_name}>{res?.campaign_name ? `${res?.campaign_name.length > 30 ? res?.campaign_name?.slice(0, 30) + '....' : res?.campaign_name}` : 'Not Available'}</td>
                                            {/* <td>{res?.user_id?.phone_no ? (res.user_id?.country_code.startsWith("+"))?  `${res.user_id?.country_code}-${res.user_id?.phone_no}`: `+${res?.user_id?.country_code}-${res?.user_id?.phone_no}`: 'Not Available'}</td> */}
                                            <td><p><b>&#36;</b>{res?.total_price ? res?.total_price : "Not Aaviable"}</p></td>
                                           <td><p><b>&#36;</b>{res?.price_money_amount ? res?.price_money_amount : "Not Aaviable"}</p></td>
                                            <td>{res?.is_money_transfer?"PAID":"UNPAID"}</td>
                                            <td>{moment(Number(res?.created_at)).format("DD-MM-YYYY")}</td>

                                            <td>
                                             <div className="btn-group gap-2">
                                                 {/* <Link to={`/user/${res?.user_id?._id}`} className="btn btn-white btn-sm">Pay</Link> */}
                                                 <button  className='btn btn-primary' disabled={res?.is_money_transfer || false} onClick={()=>handlePayMoney(res?._id)}>{"Pay"}</button>
                                             </div>
                                            </td>
                                     </tr>
                                    ) : <tr><td className='text-center py-3' colSpan={6}><img src={NODATA} width="100" /><p className='text-center mt-3'>No data found</p></td></tr>}


                                </tbody>
                            </table>
                        </div>}
                        
                        <div className='dashboad-pagination-box'>
                        <PaginationLayout
                            count={userRequestCount}
                            data={userRequestData?.data?.data}
                            page={Number(page)}
                            limit={Number(limit)}
                            loading={loading}
                            onPageChange={(val: any) => onChangePagination(val)}
                        />
                        </div>
                </div>
            </div>
            <DownloadFileModal exportData={exportData} />
        </div>
    )
}
export default UserOrderDetails;