const FIREBASE_API_KEY = "AIzaSyD1jVs5JNSfiA7Jr83uUMDFBUkCYmSHB9g"
const FIREBASE_AUTH_DOMAIN = "quantitysavers.firebaseapp.com"
const FIREBASE_PROJECT_ID = "quantitysavers"
const FIREBASE_STORAGE_BUCKET = "quantitysavers.appspot.com"
const FIREBASE_MESSAGE_SENDER_ID = "285337494679"
const FIREBASE_APP_ID = "1:285337494679:web:8443e37d131c86a22735d2"
const FIREBASE_MEASURMENT_ID = "G-V87Y8CF1TT"
const FIREBASE_VAP_ID_KEY = "BF7fGBI6xycrteOE5gcIMPN-CyxZc_AFrMqfwvtzAEn5Ro7VjNXC_sXCXbB6xV-DIxjq12EL0kzfik67ErDYo8M"

export default {
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGE_SENDER_ID,
    FIREBASE_APP_ID,
    FIREBASE_MEASURMENT_ID,
    FIREBASE_VAP_ID_KEY,
}