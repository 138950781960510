// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import env from "./env";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJECT_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MESSAGE_SENDER_ID,
    appId: env.FIREBASE_APP_ID,
    measurementId: env.FIREBASE_MEASURMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (typeof window !== "undefined") {
    const analytics = getAnalytics(app);
}
export const getFirebaseMessageToken = async () => {
    let isSupport = await isSupported()
    if (isSupport) {
        const messaging = getMessaging(app);
        try {
            let tokenId = await getToken(messaging, { vapidKey: env.FIREBASE_VAP_ID_KEY, })
            return { tokenId }
        } catch (error) {
            return { error }
        }
    }
    else {
        return { error: " Notification Not Supported" }
    }
}
// export const onMessageListener = () => {
//     const messaging = getMessaging(app);
//     return new Promise((resolve, reject) => {
//         if (messaging) {
//             messaging.onMessage((payload) => {
//                 resolve(payload)
//             })
//         } else {
//             reject(null)
//         }
//     })
// }
export const requestNotification = async () => {    
    if (!("Notification" in window)) {
        return ""
    }
    else if (Notification?.permission === "granted") {
        let { tokenId, error } = await getFirebaseMessageToken()
        
        if (error) {
            return ""
        }
        
        return tokenId
    }
    else if (Notification?.permission !== 'denied' ) {
        if(Notification?.permission === 'default'){
        Notification.requestPermission().then(async (permission) => {
            if (permission === "granted") {
                
                let { tokenId, error } = await getFirebaseMessageToken()
                if (error) {
                    return ""
                }
                return tokenId

            } else {
                return ""
            }
        });
    }
    } else {
        return ""
    }
}
export default app