
enum OrderStatus {
    PLACED = "PLACED",
    PAID = "PAID",
    SHIPPED = "SHIPPED",
    CONFIRMED = "CONFIRMED",
    CANCELLED = "CANCELLED",
    DELIVERED = "DELIVERED",
    DISPUTE = "DISPUTE",
    RETURNED = "RETURNED",
    REFUND_IN_PROGESS = "REFUND_IN_PROGESS",
    REFUNDED = "REFUNDED",
    PENDING_CANCELLATION = "PENDING_CANCELLATION"
}
enum Roles {
    DASHBOARD = "DASHBOARD",
    USERS = "USERS",
    SELLER = "SELLERS",
    CAMPAIGN = "CAMPAIGN",
    DELIVERY = "DELIVERY",
    REPORTS = "REPORT",
    FORUM = "GROUP",
    PRODUCTS = "PRODUCTS",
    ORDER = "ORDERS",
    COUPONS = "COUPON",
    HOME_MANAGEMENT = "HOMEPAGE",
    CATEGORY = "CATEGORY",
    BRAND = "BRAND",
    FAQ = "FAQS",
    CONTENT = "CONTENT",
    REATING = "REATING",
    CONTACT = "CONTACT_US",
    NOTIFICATION = "NOTIFICATION",
    DB_BACKUP = "DB_BACKUP",
    SETTING = "SETTING",
    STAFF_MEMBER = "STAFF_MEMBER",
    LANGUAGE = "LANGUAGE",
    PAYOUT = "PAYOUT"
}
enum Invoice {
    SELLER = 'SELLER',
    USER = 'USER',
}
enum Language {
    ENGLISH = "ENGLISH",
    ARABIC = "ARABIC"
}

export default {
    OrderStatus,
    Roles,
    Invoice,
    Language
}