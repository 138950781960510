import '../../assets/styles/pages.scss'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import henceforthApi from '../../utils/henceforthApi'
import { CampaignCancelInterface } from '../../context/interfaces'
import PaginationLayout from '../../components/PaginationLayout'
import { GlobalContext, handleError } from '../../context/Provider'
import profile_placeholder from '../../assets/images/pages/profile_placeholder.png'
import BreadCrumb from '../../components/common/BreadCrumb'
import Spinner from '../../components/BootstrapCompo'
import { INSUFFICIENT_PERMISSIONS } from '../../context/actionTypes'
import NODATA from '../../assets/images/no-data-found.svg'
import { capitalise } from '../../utils/validations'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'


const CampaignCancellationList = () => {
    let breadCrumbPath = [
        { name: 'Home', url: `/`, active: '' },
        { name: 'Campaign list', url: `/campaign/1`, active: '' },
        { name: 'Campaign Cancellation List', url: `/campaign-cancelled-list/1`, active: 'not-allowed' }
    ]
    const match = useMatch("campaign-cancelled-list/:page")
    const location = useLocation()
    const newParam = new URLSearchParams(location.search);
    const { authState, onChangePagination } = useContext(GlobalContext);
    let limit = 10
    const [search, setSearchData] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [state, setstate] = useState({
        data: {
            data: [],
            count: 0
        }
    } as CampaignCancelInterface)

    const onChnageDelete = async (id: any) => {
        console.log(id);
        // setloading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure want to delete this Request!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    let apires = await henceforthApi.campaign.DeleteCampaign(id)
                    toast.success(apires.data.message)
                    initialise();
                } catch (error) {
                    handleError(error)
                } finally {
                    setLoading(false)
                }
            }
        })
    }

    const initialise = async () => {
        try {
            setLoading(true)
            let q = newParam.get("filter")
            let searchData = newParam.has("search") ? newParam.get("search") : null;
            let Apires = await henceforthApi.campaign.getcampaginRequestList(
                searchData,
                q,
                Number(match?.params?.page) - 1,
                limit
            )
            setstate(Apires)
        } catch (error: any) {
            if (error?.response?.body?.error === INSUFFICIENT_PERMISSIONS) {
                window.history.back()
            }
        } finally {
            setLoading(false)
        }
    }
    const onSearch = (searchall: any) => {
        if (searchall) {
            navigate(`/campaign-cancelled-list/1?search=${searchall}`)
        }
        else {
            navigate(`/campaign-cancelled-list/1`)
        }
    }
    const onChangeFilter = (type: any) => {
        const newParam = new URLSearchParams()
        if (type) {
            newParam.set("filter", type)
        }
        navigate({ search: newParam.toString() })
    }

    const copyText = (id: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`ID copy Successfully`)
        }
    }

    useEffect(() => {
        initialise()
    }, [match?.params?.page, newParam.get("search"), newParam.get("filter"), authState?.lang])

    return (
        <>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />

            {/* page  */}
            <div className='page-spacing'>
                <section className='product-listing'>
                    <div className="container-fluid px-xs-0">
                        {/* search-filter-export */}
                        <div className='common-card mb-4 border-0 card-spacing'>
                            <div className="row justify-content-between gy-3">
                                {/* serach and filter  */}
                                <div className="col-12 col-md-8 col-lg-7">
                                    <div className="row gy-3">
                                        <div className="col-md-5">
                                            <div className='form-fields-box'>
                                                <label className='mb-1 form-label fw-semibold'>Search</label>
                                                <div className='position-relative'>
                                                    <input type="search" className="form-control rounded-0 ps-4 "
                                                        placeholder="Search by Name "
                                                        onChange={(e: any) => { setSearchData(e.target.value); onSearch(e.target.value) }} />
                                                    <span className='search-icon'><i className='fa fa-search'></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* filter */}
                                        <div className="col-md-4">
                                            <div className='form-select-box'>
                                                <label className='mb-1 form-label fw-semibold'>Filter</label>
                                                <select className="form-select shadow-none" aria-label="Default select example"
                                                    onChange={(e) => onChangeFilter(String(e.target.value))} value={String(newParam.get("filter")).toUpperCase()}>
                                                    <option value="">ALL</option>
                                                    <option value="PENDING_DELETE">PENDING</option>
                                                    <option value="DELETE">DELETE</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* table  */}
                        {loading ? <div className='d-flex justifly-content-center'><Spinner /></div> :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h5>Campaign Cancellation List</h5>
                                            </div>
                                        </div>
                                        <div className="common-card-content">
                                            {/* table */}
                                            <div className='data-list-table table-responsive mb-3'>
                                                <table className="table table-striped align-middle">
                                                    <thead className=''>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                            <th>User Name</th>
                                                            <th>Campaign Name</th>
                                                            <th>Status</th>
                                                            <th>Cancel Reason</th>
                                                            <th>Cancel Reason Description</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(state?.data?.data) && state?.data?.data.length ? state?.data?.data.map((res: any, index: any) =>
                                                            <tr key={res._id}>
                                                                <td>{Number(match?.params?.page) == 0 ? index + 1 : (Number(match?.params?.page) - 1) * limit + (index + 1)}</td>
                                                                <td className='product-image-table' >
                                                                    <Link to={`/user/${res?.created_by?._id}`}>
                                                                        <img src={res?.created_by?.profile_pic ? henceforthApi.FILES.imageOriginal(res?.created_by?.profile_pic) : profile_placeholder} alt="img" className='rounded-circle me-2' /> {res?.created_by?.name ? `${res?.created_by?.name}` : "Not Available"}
                                                                    </Link>
                                                                </td>
                                                                <td className='product-image-table'>
                                                                    <Link to={`/campaign/${res._id}/view`}>
                                                                        <img src={res.image ? henceforthApi.FILES.imageOriginal(res.image) : profile_placeholder} alt="img" className='rounded-circle me-2' /> {res.campaign_name ? `${res.campaign_name}` : "Not Available"}
                                                                    </Link>
                                                                </td>
                                                                <td className={`${res?.status === 'PENDING_CANCELLATION' ? 'text-warning' : "text-danger"}`}>{res?.status ? capitalise(res?.status) : `Not Available`}</td>
                                                                <td>{res?.cancel_reasson?.cancellation_reason ? res?.cancel_reasson?.cancellation_reason : `Not Available`}</td>
                                                                <td>{res?.cancel_reasson?.description ? res?.cancel_reasson?.description : `Not Available`}</td>
                                                                <td>{res.status != 'CANCELLED' &&
                                                                    <div className="btn-group gap-2">
                                                                        <button className="btn btn-white btn-sm" onClick={() => onChnageDelete(res?._id)} ><i className="fa fa-trash me-1"></i>Delete</button>
                                                                    </div>}
                                                                </td>
                                                            </tr>
                                                        ) : <tr><td colSpan={7} className="text-center py-3"><img src={NODATA} width="100" /><p className='text-center mt-3'>No data found</p></td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* pagination  */}
                                            <div className='dashboad-pagination-box'>
                                                <PaginationLayout
                                                    count={state.data.count}
                                                    data={state?.data?.data}
                                                    page={Number(match?.params?.page)}
                                                    limit={Number(limit)}
                                                    // loading={loading}
                                                    onPageChange={(val: any) => onChangePagination(val)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </section>
            </div>
        </>
    )
}
export default CampaignCancellationList;