import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import React, { Fragment, useContext, useEffect, useState } from 'react';
import henceforthApi from '../../utils/henceforthApi';
import { sellerdetails } from '../../context/interfaces';
// import ProductListingDetails from './SellerProductListing';
import { GlobalContext, handleError } from '../../context/Provider';
import { toast } from 'react-toastify'
import Spinner from '../../components/BootstrapCompo';
// import SellerOrderListing from './SellerOrderListing';
import Swal from 'sweetalert2';
import profile_placeholder from '../../assets/images/pages/profile_placeholder.png'
import { numberWithCommas } from '../../utils/validations';
import profile_img from '../../assets/images/pages/dummy-image.jpg';
import moment from 'moment';
import NODATA from '../../assets/images/no-data-found.svg'
import PaginationLayout from '../../components/PaginationLayout';
import { INSUFFICIENT_PERMISSIONS } from '../../context/actionTypes';

const ViewCampaign = () => {
    const { setLoading, authState, onChangePagination, authDispatch } = React.useContext(GlobalContext)
    const location = useLocation()
    const navigate = useNavigate();
    const newParam = new URLSearchParams(location.search)
    const match: any = useMatch("/campaign/:id/view")
    const [loading, setloading] = useState(false)
    const [campaignD, setCampaignD] = useState({} as any)
    const [showMore, setShowMore] = useState(false)
    const limit = 10
    const page = 1
    const [state, setstate] = useState({
        data: {
            data: [],
            count: 0
        }
    } as any)

    const CampaignDetails = async () => {
        setLoading(true)
        try {
            const res = (await henceforthApi.campaign.campaignDetails(match?.params.id)).data
            setCampaignD(res)
        } catch (err: any) {
            console.log(err.response.body.error_description);
            handleError(err);
        } finally {
            setLoading(false)
        }
    }

    const onChangeFilter = (type: any) => {
        const newParam = new URLSearchParams()
        if (type) {
            newParam.set("filter", type)
        }
        navigate({ search: newParam.toString() })
    }

    const CampaignUserList = async () => {
        try {
            setLoading(true)
            let q = newParam.get("filter")
            let Apires = await henceforthApi.campaign.getcampaginUserList(
                match?.params.id,
                q,
                Number(page) - 1,
                limit
            )
            setstate(Apires)
        } catch (error: any) {
            if (error?.response?.body?.error === INSUFFICIENT_PERMISSIONS) {
                window.history.back()
            }
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        CampaignDetails();
    }, [match?.params.id])

    useEffect(() => {
        CampaignUserList()
    }, [newParam.toString()])

    return (
        <Fragment>
            {/* breadcrum  */}
            <section className="breadcrum-box">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            {/* title  */}
                            <h2 className="fw-semibold">View Campaign</h2>
                            {/* breadcrum  */}
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="">Home</Link></li>
                                    <li className="breadcrumb-item active fw-bold">View Campaign</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            {loading ?
                <div className='vh-100 d-flex justify-content-center py-5'>
                    <Spinner />
                </div> :
                <div className='page-spacing'>
                    <section className='product-detail'>
                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-sm-12">
                                    {/* Title  */}
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <div className='d-flex mb-2 flex-column flex-md-row product-detail-params align-items-center justify-content-md-between'>
                                                <h5 className='mb-2 m-0 mb-md-0'>Campaign Details</h5>
                                                <div className='d-sm-flex'>
                                                    <div className="btn-group gap-2 me-sm-2 justify-content-center d-flex">
                                                        {/* <button type="button" className="btn btn-white btn-sm"> <i className="fa fa-download me-1"></i>Download PDF</button> */}
                                                        {/* <Link to="invoice" className="btn btn-white btn-sm text-center" target="_blank"> <i className="fa fa-user me-1"></i>{language_data?.[authState.lang]?.common_download_seller_invoice}</Link> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* Profile  */}
                                        <div className="common-card-content campaign-content">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-5">
                                                    <div className="campaign-video">
                                                        <video src={campaignD.video ? `${henceforthApi.API_FILE_ROOT_VIDEO}${campaignD.video}` : "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"} autoPlay></video>
                                                        <div className="campaign-image">
                                                            <img src={campaignD.image ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${campaignD.image}` : profile_img} alt="img" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Campaign detail  */}
                                                <div className="col-md-7">
                                                    <div className="product-detail-box">
                                                        <Link className='text-black text-decoration-none text-capitalize' to={`/campaign/${campaignD?._id}/view`}> <h2 className='fw-bolder'>Campaign Name : {campaignD?.campaign_name ? campaignD?.campaign_name : `Not Available`}</h2></Link>
                                                        <div className='d-flex align-items-center gap-1'>
                                                            <h2 className='fw-semibold m-0'>&#36; {campaignD?.total_price ? numberWithCommas(campaignD?.total_price) : `Not Available`}</h2><small>Tax</small>
                                                        </div>
                                                        <div className="divider my-3"></div>
                                                        <div className='mb-3'>
                                                            <h4 className='fw-bolder'>Campaign Description</h4>
                                                            <p className='text-truncate w-75 fw-semibold text-capitalize' title={campaignD?.description?.replace(/<\/?[^>]+(>|$)/g, '')} dangerouslySetInnerHTML={{ __html: campaignD?.description }}></p>
                                                            {/* <p className='fw-semibold text-capitalize' dangerouslySetInnerHTML={{__html:orderD?.description}}>{orderD?.description ? `${orderD?.description.length > 1000 ? !showMore ? `${orderD?.description?.slice(1000)}` : orderD?.description : orderD?.description}` : `${language_data?.[authState.lang]?.common_not_available}`}</p> */}
                                                            {/* <p className='fw-semibold text-capitalize' dangerouslySetInnerHTML={{ __html: campaignD?.description }}></p> */}
                                                            {/* {campaignD?.description?.length > 1000 ? <p className='ReadMore' role="button" onClick={() => setShowMore(!showMore)}>{!showMore ? `Read More` : `Read Less`} </p> : ''} */}
                                                        </div>
                                                        <ul className='list-unstyled product-detail-list'>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Total Campaign Price:</b><span>&#36; {campaignD?.total_price ? numberWithCommas(campaignD?.total_price) : `0`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Wholesale Price::</b><span>&#36; {campaignD?.one_product_price ? numberWithCommas(campaignD?.one_product_price) : `0`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Quantity (Sold/Available):</b><span>{campaignD?.total_quantity ? campaignD?.total_quantity - campaignD.quantity : `N/A`}/{campaignD?.quantity ? campaignD?.quantity : `N/A`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Campaign Status:</b><span className={campaignD?.status === 'ONGOING' ? 'text-success' : 'text-danger'}>{campaignD?.status ? campaignD?.status : `Not Available`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Campaign Start Date:</b><span>{campaignD?.start_date ? moment(Number(campaignD?.start_date)).format('DD-MMM-YYYY') : `Not Available`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Campaign End Date:</b><span>{campaignD?.end_date ? moment(Number(campaignD?.end_date)).format('DD-MMM-YYYY') : `Not Available`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Total Campaign Quantity:</b><span>{campaignD?.total_quantity ? campaignD?.total_quantity : `Not Available`}</span></li>
                                                            <li className="d-flex flex-column flex-sm-row product-detail-params"><b>User Joined:</b><span>{campaignD?.user_joined ? campaignD?.user_joined : `0`}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {campaignD?.product_id != null ? <div className="row">
                                <div className="col-sm-12">
                                    {/* Title  */}
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <div className='d-flex mb-2 flex-column flex-md-row product-detail-params align-items-center justify-content-md-between'>
                                                <h5 className='mb-2 m-0 mb-md-0'>Product Details</h5>
                                                <div className='d-sm-flex'>
                                                    <div className="btn-group gap-2 me-sm-2 justify-content-center d-flex">
                                                        {/* <button type="button" className="btn btn-white btn-sm"> <i className="fa fa-download me-1"></i>Download PDF</button> */}
                                                        {/* <Link to="invoice" className="btn btn-white btn-sm text-center" target="_blank"> <i className="fa fa-user me-1"></i>{language_data?.[authState.lang]?.common_download_seller_invoice}</Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Profile  */}
                                        <div className="common-card-content">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                                                        <div className="carousel-indicators">
                                                            {Array.isArray(campaignD?.product_id?.images) && campaignD?.product_id?.images?.map((res: string, index: number) => {
                                                                return (
                                                                    <button type="button" key={index} data-bs-target='#carouselExampleIndicators' data-bs-slide-to={index} className={`${index === 0 ? "active" : ""} ${campaignD?.product_id?.images.length === 1 ? "d-none" : ""}`} aria-current="true" aria-label={`Slide ${index + 1}`}></button>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className="carousel-inner product-images">
                                                            {Array.isArray(campaignD?.product_id?.images) && campaignD?.product_id?.images?.map((res: string, index: number) => {
                                                                return (
                                                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                                                        <img src={res ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${res}` : profile_img} className="d-block w-100" alt="img" />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Product detail  */}
                                                <div className="col-md-7">
                                                    <div className="product-detail-box">
                                                        <Link className='text-black text-decoration-none text-capitalize' to={`/product/${campaignD?.product_id?._id}`}> <h2 className='fw-bolder'>Product Name : {campaignD?.product_id?.name ? campaignD?.product_id?.name : `Not Available`}</h2></Link>
                                                        <div className='d-flex align-items-center gap-1'>
                                                            <h2 className='fw-semibold m-0'>&#36; {campaignD?.product_id?.price ? numberWithCommas(campaignD?.product_id?.price) : `Not Available`}</h2><small>Tax Include</small>
                                                        </div>
                                                        <div className="divider my-3"></div>
                                                        <div className='mb-3'>
                                                            <h4 className='fw-bolder'>Product Description</h4>
                                                            <p className='fw-semibold text-capitalize'>{campaignD?.product_id?.description ? `${campaignD?.product_id?.description.length > 1000 ? !showMore ? `${campaignD?.product_id?.description?.slice(1000)}` : campaignD?.product_id?.description : campaignD?.product_id?.description}` : `Not Available`}</p>
                                                            {campaignD?.product_id?.description?.length > 1000 ? <p className='ReadMore' role="button" onClick={() => setShowMore(!showMore)}>{!showMore ? `Read More` : `Read Less`} </p> : ''}
                                                        </div>
                                                        <ul className='list-unstyled product-detail-list'>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Order Id:</b><span>{campaignD?.order_id ? campaignD?.order_id : `Not Available`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Product Id:</b><span>{campaignD?.product_id?.prodct_id ? campaignD?.product_id?.prodct_id : `Not Available`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Product Name:</b><span>{campaignD?.product_id?.name ? campaignD?.product_id?.name : `Not Available`}</span></li>
                                                            {/* <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Campaign Create Type:</b><span>{campaignD?.product_id?.campaign_create_type ? campaignD?.product_id?.campaign_create_type : `Not Available`}</span></li> */}
                                                            {/* <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Wholesale price:</b><span>&#36; {campaignD?.product_id?.wholesale_price ? numberWithCommas(campaignD?.product_id?.wholesale_price) : `0`}</span></li> */}
                                                            {/* <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Wholesale Quantity:</b><span>{campaignD?.product_id?.wholesale_quntity ? campaignD?.product_id?.wholesale_quntity : `0`}</span></li> */}
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Tax Percentage:</b><span>{campaignD?.product_id?.tax_percentage ? campaignD?.product_id?.tax_percentage.toFixed(2) : `0`}%</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Discount (%) :</b><span>{campaignD?.product_id?.discount ? campaignD?.product_id?.discount : `0`}%</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Brand :</b><span>{campaignD?.product_id?.brand_id.name ? campaignD?.product_id?.brand_id.name : `Not Available`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Category:</b><span>{campaignD?.product_id?.category_id?.name ? campaignD?.product_id?.category_id?.name : `Not Available`}</span></li>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Sub Category :</b><span>{campaignD?.product_id?.subcategory_id?.name ? campaignD?.product_id?.subcategory_id?.name : `Not Available`}</span></li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}
                            {/* User seller order-status detail  */}
                            <div className="row gy-3 mt-3">
                                {/* User Detail  */}
                                <div className="col-md-6 col-lg-3">
                                    <div className="common-card h-100  ">
                                        <div className="common-card-title">
                                            <h5>Creator Detail</h5>
                                        </div>
                                        <div className="common-card-content">
                                            <div className="product-detail-box">
                                                <div className="profile-image">
                                                    <img src={campaignD?.created_by?.profile_pic !== null ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${campaignD?.created_by?.profile_pic}` : profile_img} alt="img" className='img-fluid' />
                                                </div>
                                                {/* Profile Detail  */}
                                                <div className="profile-image my-4">
                                                    <h5 className='mb-3'>{campaignD?.created_by?.name ? campaignD?.created_by?.name : `Not-Available`}</h5>
                                                    <p className="d-flex align-items-start mb-2"><i className='fa fa-envelope me-2 fs-5'></i>{campaignD?.created_by?.email ? campaignD?.created_by?.email : `N/A`}</p>
                                                    <p className="d-flex align-items-start mb-2"><i className='fa fa-phone-square me-2 fs-5'></i>{campaignD?.created_by?.country_code ? + campaignD?.created_by?.country_code : "N/A"} {campaignD?.created_by?.phone_no ? campaignD?.created_by?.phone_no : ""}</p>
                                                    {/* <p className="d-flex align-items-start"><i className='fa fa-map me-2 fs-5'></i>{campaignD?.address_id?.full_address ? campaignD?.address_id?.full_address : `${language_data?.[authState.lang]?.common_not_available}`}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* User seller order-status detail  */}
                                <div className="col-md-12 col-lg-9">
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h5>Campaign User List</h5>
                                                <div className='form-select-box'>
                                                    <label className='mb-1 form-label fw-semibold'>Filter</label>
                                                    <select className="form-select shadow-none" aria-label="Default select example" onChange={(e) => onChangeFilter(String(e.target.value))} value={String(newParam.get("filter")).toUpperCase()}>
                                                        <option value="">ALL</option>
                                                        <option value="JOINED">JOINED</option>
                                                        <option value="EXITED">EXITED</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="common-card-content">
                                            {/* table */}
                                            <div className='data-list-table table-responsive mb-3'>
                                                <table className="table table-striped align-middle">
                                                    <thead className=''>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                            <th>User Name</th>
                                                            <th>Status</th>
                                                            <th>Quantity</th>
                                                            <th>Total Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(state?.data?.data) && state?.data?.data.length ? state?.data?.data.map((res: any, index: any) =>
                                                            <tr key={res.id}>
                                                                <td>{Number(page) == 0 ? index + 1 : (Number(page) - 1) * limit + (index + 1)}</td>
                                                                <td className='product-image-table'>
                                                                    <img src={res?.user_id?.profile_pic ? henceforthApi.API_FILE_ROOT_ORIGINAL + res?.user_id?.profile_pic : profile_placeholder} alt="img" className='rounded-circle me-2' /> {res.user_id?.name ? `${res.user_id?.name}` : "Not Available"}
                                                                </td>
                                                                <td className={res.status === 'JOINED' ? 'text-success' : 'text-danger'}>{res?.status !== 0 ? res?.status : `Not Available`}</td>
                                                                <td><b></b>{res?.total_quantity ? res?.total_quantity : `Not Available`}</td>
                                                                <td><b>&#36;</b>{res?.total_price !== 0 ? res?.total_price : `Not Available`}</td>

                                                            </tr>
                                                        ) : <tr><td colSpan={10} className="text-center py-3"><img src={NODATA} width="100" /><p className='text-center mt-3'>No data found</p></td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* pagination  */}
                                            <div className='dashboad-pagination-box'>
                                                <PaginationLayout
                                                    count={state.data.count}
                                                    data={state?.data?.data}
                                                    page={Number(page)}
                                                    limit={Number(limit)}
                                                    onPageChange={(val: any) => onChangePagination(val)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            }
        </Fragment>
    )
}
export default ViewCampaign;