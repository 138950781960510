import React, { Fragment, useEffect, useState } from "react";
import defaultImage from '../../assets/images/pages/profile_placeholder.png'
import profile_img from '../../assets/images/pages/profile_placeholder.png';
import BreadCrumb from "../../components/common/BreadCrumb";
import { GlobalContext, handleError } from "../../context/Provider";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../utils/validations";
import henceforthApi from "../../utils/henceforthApi";
import PaginationLayout from "../../components/PaginationLayout";
import NODATA from '../../assets/images/no-data-found.svg'
import Spinner from "../../components/BootstrapCompo";

const FroumDetails = () => {
    const { loading, setLoading, authState, authDispatch } = React.useContext(GlobalContext)
    const match = useMatch('/forum/:id/view')
    const [forumD, setFroumD] = useState({} as any)
    const [MemberList, setMemberListing] = useState({
        total_count: 0,
        data: []
    } as any)
    const Limit = 10;
    const location = useLocation()
    const navigate = useNavigate();
    const newParam = new URLSearchParams(location.search)
    const page = newParam.get('page') || 1
    let breadCrumbPath = [
        { name: 'Home', url: `/`, active: '' },
        { name: 'Forum list', url: `/forum/1`, active: '' },
        { name: 'Forum Details', url: ``, active: 'not-allowed' }
    ]
    const initilize = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.campaign.getForumDetails(match?.params?.id)).data
            setFroumD(res)
        } catch (err: any) {
            console.log(err.response?.body?.error_description);
            handleError(err);
        } finally {
            setLoading(false)
        }
    }
    const groupMemberListing = async () => {
        setLoading(true);
        try {
            let res = (await henceforthApi.campaign.getMemberList(match?.params?.id, Number(page) - 1, Limit, newParam.toString()))?.data
            console.log('resresresresresres', res);

            setMemberListing({
                total_count: res?.count,
                data: res?.data
            })
        } catch (err: any) {
            setMemberListing({
                total_count: 0,
                data: []
            })
            // handleError(err, 'active', authDispatch);
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        groupMemberListing();
    }, [page])
    useEffect(() => {
        initilize()
    }, [match?.params.id])
    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            <div className='page-spacing'>
                <section className='product-detail'>
                    <div className="row">
                        <div className="col-lg-4">{loading ? <Spinner /> :
                            <div className="common-card">
                                <div className="common-card-title">
                                    <h5>Forum Detail</h5>
                                </div>
                                <div className="common-card-content">
                                    <div className="product-detail-box">
                                        <div className="profile-image mb-2">
                                            <img src={forumD?.created_by?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${forumD?.created_by?.profile_pic}` : profile_img} alt="img" className='img-fluid' />
                                            <h3 className="mt-3"><span className="fw-bold"></span>Creator Name :<span className="fw-0 ms-2">{forumD?.is_default? "Default Group":forumD?.created_by?.name}</span></h3>
                                        </div>
                                        {/* <div className='mb-3'>
                                            <h4 className='fw-bolder'>{language_data?.[authState.lang]?.common_forum} {language_data?.[authState.lang]?.common_description}</h4>
                                        </div> */}
                                        <ul className='list-unstyled product-detail-list'>
                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Group Name:</b><span>{forumD?.group_name ? forumD?.group_name : `Not-Available`}</span></li>
                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Group Type:</b><span className={`${forumD.group_type == "PUBLIC" ? 'text-success' : 'text-danger'} `}>{forumD?.group_type ? forumD?.group_type : `Not-Available`}</span></li>
                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>Members Count:</b><span>{forumD?.members_count ? forumD?.members_count : `Not-Available`}</span></li>
                                        </ul>
                                    </div>
                                    <div className="text-end">
                                        {/* <button type="button" className="btn btn-theme">View Campaign</button> */}
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                        <div className="col-lg-8">
                            <div className="common-card">
                                <div className="common-card-title">
                                    <h5>Group Members Listing </h5>
                                </div>
                                <div className="common-card-content">
                                    {/* table */}
                                    <div className='data-list-table table-responsive mb-4 text-center'>
                                        {loading ? <Spinner /> : <table className="table table-striped align-middle">
                                            <thead className=''>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Group Members Name</th>
                                                    <th>Role</th>
                                                    {/* <th>Group visibility</th> */}
                                                    {/* <th>Group members</th> */}
                                                    {/* <th>Action</th> */}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(MemberList?.data) && MemberList?.data?.length ? MemberList?.data?.map((res: any, index: number) => {
                                                    return (
                                                        <tr key={res?._id}>
                                                            <td>{(Number(page) - 1) * Limit + (index + 1)}</td>
                                                            <td className='product-image-table'>
                                                                <img src={res?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.profile_pic}` : defaultImage} alt="img" className='rounded-circle me-2' />
                                                                <span>{res?.name ? res?.name : `Not Available`}</span>
                                                            </td>
                                                            <td >{res?.role ? res?.role : `Not Available`}</td>
                                                            {/* <td>{res?.created_at ? moment(Number(res?.created_at)).format('DD MMM YYYY') : ''}</td> */}
                                                            {/* <td>
                                                                <div className="btn-group gap-2">
                                                                    <Link className="btn btn-white btn-sm" to={`/forum/${res?._id}/view`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                                </div>
                                                            </td> */}
                                                        </tr>
                                                    )
                                                }) :
                                                    <tr><td className='text-center py-3' colSpan={10}><img src={NODATA} width="100" /><p className='text-center mt-3'></p></td></tr>
                                                }
                                            </tbody>
                                        </table>}
                                    </div>
                                    {/* pagination  */}
                                    <PaginationLayout
                                        data={MemberList.data}
                                        count={Number(MemberList.total_count)}
                                        limit={Number(Limit)}
                                        page={Number(page)}
                                        onPageChange={(page) => { navigate({ search: `?page=${page}` }) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}
export default FroumDetails;